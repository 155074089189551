import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['startButton']
  static values  = {
    jobSlug: String,
    conversationId: Number,
    modalId: String
  }

  connect() {
    console.log('call controller connected!');

    const startButton = this.startButtonTarget;

    const jobSlug        = this.jobSlugValue;
    const conversationId = this.conversationIdValue;
    const modalId        = this.modalIdValue;

    $(startButton).on('click', function(){
      const generateRandomString = (length) => [...Array(length)].map(() => Math.random().toString(36)[2]).join('');
      const randomString         = generateRandomString(10); // Change 10 to the desired length

      var meet_url = `https://meet.jit.si/prowurk/${jobSlug}-${randomString}`
      var meeting  = 'meeting'

      $.ajax({
        url: "/messages",
        type: "POST",
        data : {
          message: { body: meet_url, m_for: meeting, conversation_id: conversationId },
        },
        success: function(response) {
          $(`#${modalId}`).modal('hide');
        },
      })
    });
  }
}
