import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log('connect push Controller');
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        console.log(permission);
        if (permission === "granted") {
          this.registerServiceWorker();
        } else if (permission === "denied") {
          console.warn("User rejected to allow notifications.");
        } else {
          console.warn("User still didn't give an answer about notifications.");
        }
      });
    } else {
      console.warn("Push notifications not supported.");
    }
  }

  registerServiceWorker() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((serviceWorkerRegistration) => {
          serviceWorkerRegistration.pushManager
            .getSubscription()
            .then((existingSubscription) => {
              if (!existingSubscription) {
                this.subscribeUser(serviceWorkerRegistration);
              } else {
                console.log('Subscription already exists:', existingSubscription);
                this.sendSubscriptionToServer(existingSubscription);
              }
            });
        })
        .catch((error) => {
          console.error("Error during registration Service Worker:", error);
        });
    }
  }

  subscribeUser(serviceWorkerRegistration) {
    window.vapidPublicKey = "BGyLTKuAmbDVqsWOcUMz_kllL9ZYCAl3GtfMMudUMUQzpl8A3g6pBmyygbuWJbT656kYsWEJDCbu1F-bYh4R97Y";
    serviceWorkerRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: window.vapidPublicKey,
      })
      .then((subscription) => {
        console.log('New subscription:', subscription);
        this.sendSubscriptionToServer(subscription);
      })
      .catch((error) => {
        console.error("Failed to subscribe user:", error);
      });
  }

  sendSubscriptionToServer(subscription) {
    console.log('sent to server', subscription)
    navigator.serviceWorker.ready
      .then(() => {
        $.post("/users/accept_web_push_notification", {
          subscription: subscription.toJSON(),
          message: "You successfully subscribed to web notifications"
        });
      });
  }
}
