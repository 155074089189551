import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('message input controller connected!');

    const thisElement = this.element

    $(thisElement).focus();
  }
}
