import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    conversationId: Number,
    newlyAdded:     Boolean
  }

  static targets = ['chatTab']

  connect() {
    console.log('chat item controller connected!');

    const thisElement    = this.element;
    const conversationId = this.conversationIdValue;
    const newlyAdded     = this.newlyAddedValue;

    const chatTab        = this.chatTabTarget;

    if (newlyAdded) {
      $(".chat-item-tab").removeClass("has_latest_message");
      $(thisElement).addClass("has_latest_message");
    }

    $(chatTab).click(function() {
      $(".chat-item-tab").removeClass("active");
      $(thisElement).addClass('active');
    });
  }
}
