import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['customReason', 'flagReason', 'form']

  connect() {
    console.log('report chat controller connected!');

    const customReason = this.customReasonTarget;
    const flagReason   = this.flagReasonTarget;
    const form         = this.formTarget;

    $(customReason).hide();

    function toggleCustomReason() {
      if ($(flagReason).val() === "other") {
        $(customReason).show();
      } else {
        $(customReason).hide().val("");
      }
    }

    $(flagReason).change(function() {
      toggleCustomReason();
    });

    $(form).validate({
      rules: {
        "flag[reason]": {
          required: true
        },
        "flag[custom_reason]": {
          required: function() {
            return $(flagReason).val() === "other";
          }
        }
      },
      messages: {
        "flag[reason]": {
          required: "Please select one of reason"
        },
        "flag[custom_reason]": {
          required: "Please provide a reason"
        }
      },
      errorPlacement: function(error, element) {
        if (element.attr("name") === "flag[custom_reason]") {
          error.insertAfter("#reason_custom");
        } else {
          error.insertAfter(element);
        }
      }
    });

    toggleCustomReason();
  }
}
