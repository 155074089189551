import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    modalId: String
  }

  connect() {
    console.log('modal controller connected!');

    const modalId = this.modalIdValue;

    $(`#${modalId}`).modal('show');
  }
}
