import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    conversationId: String
  }

  connect() {
    console.log('Scroll chat controller connected!');

    const conversationId = this.conversationIdValue;

    const markConversationRead = () => {
      $.ajax({
        url: `/conversations/${conversationId}/mark_as_read`,
        method: "POST"
      });
    }

    const scrollToBottom = () => {
      const chat = $('#messages');
      const scrollHeight = chat.prop("scrollHeight");

      chat.animate({
        scrollTop: scrollHeight
      }, 200);

      const proposalChat = $('#proposal-messages');
      const proposalScrollHeight = proposalChat.prop("scrollHeight");

      proposalChat.animate({
        scrollTop: proposalScrollHeight
      }, 200);

      markConversationRead();
    }

    setTimeout(scrollToBottom, 100);

  }
}
