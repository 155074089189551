import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'uploadedFile', 'messageAttachment', 'messageBody', 'messageBodyWithAttachment', 'submitBtn']
  static values  = {
    attachmentTemplateId: String,
    attachmentTemplateClass: String
  }

  connect() {
    console.log('file uploader controller connected!');

    const uploadedFile              = this.uploadedFileTarget;
    const messageAttachment         = this.messageAttachmentTarget;
    const formTarget                = this.formTarget;
    const messageBody               = this.messageBodyTarget;
    const messageBodyWithAttachment = this.messageBodyWithAttachmentTarget;
    const submitBtn                 = this.submitBtnTarget;

    const attachmentTemplateId    = this.attachmentTemplateIdValue;
    const attachmentTemplateClass = this.attachmentTemplateClassValue;

    $(messageAttachment).fileupload({
      url:               '/messages/save_attachment',
      dataType:          'json',
      sequentialUploads: true,
      add: function(e, data) {
        var uploadErrors = [];
        var file         = data.files[0];

        // file size validation
        // 1000 * 1000 = 1mb
        if(file.size > 500000000) {
          uploadErrors.push('file should be less than 500 mb');
          alert("File should be less than 500 mb");
        }

        // submit form if there are no errors
        if(uploadErrors.length <= 0) {
          $(submitBtn).prop('disabled', true);
          data.context = $(tmpl(attachmentTemplateId, file));
          $(uploadedFile).append(data.context);
          return data.submit();
        }
      },
      progress: function (e, data) {
        $("#uploded_job_file").css({
          "padding": "10px"
        });
        var progress = parseInt(data.loaded / data.total * 100, 10);
        data.context.find('.bar').css('width', progress + '%');

        if (progress == 100) {
          setTimeout(function() {
            data.context.find('.progress').css('display', 'none');
            $('.file-name').show();
            $('.file-delete-link').show();
          }, 2500);
        };
      },
      done: function (e, data) {
        data.context.attr('id', data.result[0].id);
        data.context.find('a').attr('href', data.result[0].delete_url);
        data.context.attr('data-filename', data.result[0].name);
        data.context.attr('data-downloadUrl', data.result[0].download_url);

        $(submitBtn).prop('disabled', false);
        $(document).on('keydown', async (event) => {
          if ((event.key === "Enter" || event.keyCode === 13) && !event.shiftKey) {
            event.preventDefault();
            $(submitBtn).click();
          }
        });
      }
    });

    $(formTarget).on("submit", function(e) {
      var attachment_downloads_url, uploded_attachments_ids;
      e.preventDefault();

      $(messageBodyWithAttachment).val('');

      uploded_attachments_ids = $(`.${attachmentTemplateClass}`).map(function() {
        return [[this.id, $(this).data('filename'), $(this).data('downloadurl')]];
      }).get();

      if (uploded_attachments_ids.length > 0) {
        attachment_downloads_url = "";

        $.each(uploded_attachments_ids, function(index, elm) {
          return attachment_downloads_url = attachment_downloads_url + "\n\n" + "<a href=" + elm[2] + ">" + elm[1] + "</a>";
        });

        $(`.${attachmentTemplateClass}`).remove();
        $("#uploded_job_file").css({
          "box-shadow": "",
          "border": "",
          "border-radius": "",
          "padding": ""
        });
        $(messageBodyWithAttachment).val($(messageBody).val() + "\n" + attachment_downloads_url);
        $(messageBodyWithAttachment).val($(messageBodyWithAttachment).val());
      }
    });
  }
}
