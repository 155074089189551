import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['messagesList', 'paginationFooter', 'callButton', 'messageBody', 'messageAttachment', 'submitBtn']
  static values  = {
    conversationId:        Number,
    conversationSlug:      String,
    conversationIsBlocked: Boolean,
    conversationBlockedBy: Number,
    currentUserId:         Number,
    messagesTotalPages:    Number,
    currentPage:           Number,
    isLoadingMessages:     Boolean,
    isMobile:              Boolean,
    loadMessages:          Boolean
  }

  connect() {
    console.log('chat main controller connected!');
    var currentPath = window.location.pathname;
    var newSlug = this.conversationSlugValue;
    var updatedUrl = currentPath.replace(/\/[^/]*$/, '/' + newSlug);
    window.history.pushState({}, '', updatedUrl);

    const messagesList          = this.messagesListTarget;
    const paginationFooter      = this.paginationFooterTarget;
    const callButton            = this.callButtonTarget;
    const messageBody           = this.messageBodyTarget;
    const messageAttachment     = this.messageAttachmentTarget;
    const submitBtn             = this.submitBtnTarget;

    const conversationId        = this.conversationIdValue;
    const conversationIsBlocked = this.conversationIsBlockedValue;
    const conversationBlockedBy = this.conversationBlockedByValue;
    const currentUserId         = this.currentUserIdValue;
    const messagesTotalPages    = this.messagesTotalPagesValue;
    const isMobile              = this.isMobileValue;
    const loadMessages          = this.loadMessagesValue;

    const loadMoreMessages = this.loadMoreMessages.bind(this);

    if (isMobile && loadMessages) {
      $(this.element).addClass('show_chat_thread');
    }

    $('.back_chat_panel').click(function() {
      $(this).closest('.chat-thread-area').removeClass('show_chat_thread');
    });

    const autosize = () => {
      var el = this;
      setTimeout(function(){
        if (el.value.trim() != '') {
          el.style.cssText = 'height:' + el.scrollHeight + 'px';
        } else {
          el.style.cssText = 'height:' + 40 + 'px';
        }
      }, 0);
    }

    // var textarea = document.querySelector('textarea');
    // textarea.addEventListener('keydown', autosize);

    // INFINITE PAGINATION
    $(messagesList).scroll(function() { if ($(messagesList).scrollTop() == 0) loadMoreMessages() });

    // HANDLE BUTTONS
    if (conversationIsBlocked) {
      $(callButton).removeAttr('data-target');
      $(messageBody).prop( "disabled", true );
      $(messageAttachment).prop("disabled", true);
      $(submitBtn).prop("disabled", false);
      $(messageBody).attr("placeholder", conversationBlockedBy != currentUserId ? "You can't reply to this conversation as you are blocked!" : "Please unblock the user to continue this conversation.");
    } else {
      $(messageBody).prop("disabled", false);
      $(messageBody).attr("placeholder", "Type your message!")
    }
  }

  loadMoreMessages() {
    const setIsLoadingMessages = this.setIsLoadingMessages.bind(this);

    if (this.isLoadingMessagesValue) return;

    const messagesList = this.messagesListTarget;

    var prevScrollHeight = $(messagesList)[0].scrollHeight;

    if (this.currentPageValue < this.messagesTotalPagesValue) { 
      setIsLoadingMessages(true);
      this.currentPageValue++;

      $.ajax({
        url: `/conversations/${this.conversationSlugValue}`,
        type: 'GET',
        dataType: 'script',
        data: { page: this.currentPageValue, load_messages: true },
        success: function() {
          $(messagesList).scrollTop($(messagesList)[0].scrollHeight - prevScrollHeight);
          prevScrollHeight = $(messagesList)[0].scrollHeight;
          setIsLoadingMessages(false);
        },
        error: function() {
          setIsLoadingMessages(false);
        }
      });
    }
  }

  setIsLoadingMessages(value) {
    this.isLoadingMessagesValue = value;
  }
}
